import produce from 'immer'
import axios from 'axios'
import {setGlobalState} from 'GlobalStore';
let amplitude  = require('amplitude-js');
var mixpanel = require('mixpanel-browser');




mixpanel.init("72a7ed063fb35f703ac7d7065562c301");
amplitude.getInstance().init("a23b9932b3c3db7cde04871192cea47a", null, {
	includeUtm: true,
	includeReferrer: true,
	includeGclid: true
});

let instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL, 
	timeout: 60000,
});
export function track(name, data){
	console.log({track:name, data})
	if(process.env.NODE_ENV==="production"){
		window.fbq && window.fbq('track', name, data);
		window._dcq && window._dcq.push(["track", name,data]);
		amplitude && amplitude.getInstance().logEvent(name, data);
		mixpanel && mixpanel.track(name, data);
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': name,
			...data
		});

	}

}
export const fetchPackages = (name) => get(`/public/packages`)
export const fetchPackage = (name, slug) => get(`/public/packages/${slug}`)

export const fetchCategories = (name) => get(`/public/categories`)
export const fetchCourses = () => get('/public/courses')
export const fetchCourse = (name, slug) => get(`/public/courses/${slug}`)
export const fetchAuthor = (name, id_autor) => get(`/public/authors/${id_autor}`)

export const getDiscount = (codigo, id_curso) => get(`/discount/apply`,{codigo, id_curso})
export const login = (user) => post(`/user-login`,user,{fullError:true})
export const register = (user) => post(`/user-register`,user)
export const addCard = (card_token, device_session_id) => post(`users/me/cards`,{card_token, device_session_id})
export const fetchMe = (name) => get(`/users/me`)
export const buySubscription = ({id_suscripcion, purchase, appType}) => post(`users/me/subscribe`,{id_suscripcion, purchase, appType})
export const fetchMyCourses = () => get(`/users/me/courses`)
export const buyCourse = ({idCurso, idTarjeta, idDescuento, total, device_session_id, tipo}) => {
	if(tipo==="paquete"){
		return post(`packages/${idCurso}/web-buy`,{idTarjeta, idDescuento, total, device_session_id});
	}else{
		return post(`courses/${idCurso}/web-buy`,{idTarjeta, idDescuento, total, device_session_id});
	}
}
export const buyCoursePaypal = ({idCurso, idOrden, idDescuento, total, subtotal, tipo}) => {
	if(tipo==="paquete"){
		return post(`packages/${idCurso}/paypal-buy`,{idOrden, idDescuento, total, subtotal});
	}else{
		return post(`courses/${idCurso}/paypal-buy`,{idOrden, idDescuento, total, subtotal});
	}
}
export const fetchComments = (name, id_clase) => get(`/comments/${id_clase}`)
export const fetchComment = (name, id_clase, id_comentario) => get(`/comments/${id_clase}/${id_comentario}`)
export const postComment = async ({id_curso, id_clase, comentario, files}) => {
	let response = await post(`/comments`,{id_curso, id_clase, comentario});
	if(files){
		await upload(`/comments/${response.data._id}/file`, [...files]);
	}
	return response;
}
export const postReply = ({id_comentario, id_curso, id_clase, comentario}) => post(`/comments/${id_comentario}`,{id_curso, id_clase, comentario})

// export const buySubscription = (name) => post(`courses/${id}/buy`,{purchase, appType})
export const updateProgress = ({id_curso, id_clase, body}) => {
	console.log({id_curso, id_clase, body})
	return put(`users/me/courses/${id_curso}/classes/${id_clase}`, body)
}
export const init = token => {
	if(token){
		window.localStorage.setItem("vitallier:user", token);
	}else{
		token = window.localStorage.getItem("vitallier:user");
	}
	
	if(token){
		instance.defaults.headers["Authorization"] = `Bearer ${token}`;
		// isLoggedIn = true;
		setGlobalState('isLoggedIn',true);
		fetchMe().then(me=>{
			console.log({me});
			if(me){
				mixpanel.identify(me._id);
				mixpanel.people.set({
					"$email": me.correo,
					"$name": me.nombre,
					"Sign up date": me._created_date
				});

				var identify = new amplitude.Identify().set('nombre', me?.nombre).set('correo', me?.correo);
				amplitude.getInstance().identify(identify)
	
				window._dcq && window._dcq.push(["identify", {
					email: me.correo,
					first_name: me.nombre,
					tags: ["Customer"],
				}]);
			}else{
				delete instance.defaults.headers["Authorization"];
				setGlobalState('isLoggedIn',false);

				// isLoggedIn = false;
			}
			
		}).catch(()=>{
			delete instance.defaults.headers["Authorization"];
			setGlobalState('isLoggedIn',false);
		})
		// console.log({setGlobalState});
		// setGlobalState()
	}else{
		delete instance.defaults.headers["Authorization"];
		setGlobalState('isLoggedIn',false);

		// isLoggedIn = false;
	}
	
}
// export const isLoggedIn = () => {
// 	const token = window.localStorage.getItem("vitallier:user");
// 	return token ? true : false;
// }

// export let isLoggedIn = !!window.localStorage.getItem("vitallier:user");
export const get = async(url, query)=>{
	try{
		const result = await instance.get(url,{params:query});
		return result.data;
	}catch(error){
		let errmsg = error.message;
		if(error.response){
			errmsg = error.response?.data?.error;
		}
		return Promise.reject(errmsg)
	}
	
}
export const post = async(url, data, {fullError}={})=>{
	try{

		const result = await instance.post(url, data, {withCredentials: true});
		return result.data;
	} catch(error){
		console.log(JSON.stringify(error));
		let errmsg = error.message;
		if(error.response){
			errmsg = error.response?.data?.error;
		}
		if(fullError && error.response){
			if(error.response)return Promise.reject(error.response?.data)
			else return Promise.reject({message:errmsg})
		}
		return Promise.reject(errmsg)

	}
	
}
export function upload(url, files){
	return new Promise((resolve, reject)=>{
		const formData = new FormData();
		if(Array.isArray(files)){
			files.forEach(f => {
				formData.append("file",f);
			});
		}else{
			formData.append("file",files);
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			},
			timeout:180000
		};
		instance.post(url, formData, config).then(function (response) {
			resolve(response);
		}).catch(function (error){
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	}); 
}
export const put = async(url, data)=>{
	const result = await instance.put(url, data);
	return result.data;
}
 