import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from "@material-ui/styles";
import * as serviceWorker from './serviceWorker';
import Theme from "./Theme";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Cookies from 'js-cookie'
const queryParams = new URLSearchParams(window.location.search);
if(queryParams.has("ref")){
	window.Cookies = Cookies;
	Cookies.set(`vitallier_referido_por`, queryParams.get("ref"), { expires: 14
	 })
}


Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  }

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  }
}
ReactDOM.render(
	
  <React.StrictMode>
		<ThemeProvider theme={Theme}>
    	<App />
		</ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
