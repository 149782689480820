import { createMuiTheme } from "@material-ui/core/styles";

const Theme = createMuiTheme({
	typography: {
		fontFamily: 'poppinsregular',
  },
	palette: {
		primary: {
			main: "#23483E",
			contrastText: "#fff",
		},
		secondary: {
			main: "#E66C60",
			contrastText: "#fff"
		},
		warning: {
			main: "#FF0000"
		},

	},
	overrides: {
		MuiBox: {
			root: {
				padding: 0
			}
		},
		MuiButton: {
			root: {
				minWidth: "100px"
			}
		},
		MuiTabs: {
			root: {
				marginBottom: "10px"
			},
			flexContainer: {
				background: "#f8f8f8"
			},
			indicator: {
				height: "5px"
			},
		},
		MuiTab: {
			textColorInherit: {
				opacity: "1"
			}
			// wrapper: {
			//   color: "#97D95B"
			// }
		},
		MuiSvgIcon:{
			colorPrimary: {
				color: "#ffffff"
			}
		},
		MuiIcon: {
			colorPrimary: {
				color: "#ffffff"
			}
		},
		MuiPaper: {
			elevation4: {
				boxShadow: "none"
			}
		},
		MuiTypography: {
			h5: {
				fontSize: "14pt",
				fontWeight: "bold",
				color:"#2F2F2F"
			},
			h6: {
				color:"#2F2F2F"
			},
			h1: {
				fontSize: "15pt",
				color:"#2F2F2F"
			},
			h2: {
				fontSize: "14pt",
				color:"#2F2F2F"
			},
			h3: {
				color:"#2F2F2F",
				fontSize: "12pt",
			},
			h4: {
				color:"#2F2F2F"
			},
			body1: {
				color:"#999999"
			},
			body2: {
				color:"#2F2F2F"
			}
		},
		MuiFormGroup: {
			root: {
				flexWrap: "initial"
			}
		},
		MuiFormControl: {
			root: {
				width: "100%"
			}
		},
	}
});


export default Theme;
