import React from 'react';
import * as Sentry from "@sentry/react";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
		// Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto		
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
		Sentry.captureException(error, {errorInfo});

		this.setState(s=>({...s, error,errorInfo}))
    // También puedes registrar el error en un servicio de reporte de errores
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz de repuesto
      return <>
				<h1>Ha ocurrido un error, por favor actualiza la pagina. Si el error persiste contactanos a contacto@vitallier.com</h1>
				{JSON.stringify(this.state.error)}
				{JSON.stringify(this.state.errorInfo)}
			</>;
    }

    return this.props.children; 
  }
}