import React,{useState} from 'react';
import './App.css';
import NavBar from "components/NavBar";
import barra1 from "./assets/img/barra_1.png"
import barra2 from "./assets/img/barra_2.png"
import barra3 from "./assets/img/barra_3.png"
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./Routes";
import {init} from "api";
import "assets/css/slick-theme.css";
import "assets/css/slick-theme.scss";
import LoginModal from "pages/LoginModal";
import Modal from "components/Modal/Modal";
import ErrorBoundary from "components/ErrorBoundary";
import useGlobalState from "GlobalStore";
if(window.OpenPay){
	window.OpenPay.setId(process.env.REACT_APP_OPENPAY_ID);
	window.OpenPay.setApiKey(process.env.REACT_APP_OPENPAY_PUBLIC_KEY);
	window.OpenPay.setSandboxMode(process.env.NODE_ENV!=="production");
}

init();

function App() {
	const [showModalLogin, setShowModalLogin] = useGlobalState('showModalLogin');
	const [isLoggedIn] = useGlobalState('isLoggedIn');
  return (<ErrorBoundary>
		<img alt="" src={barra1} style={{position:"absolute",top:0,left:0, height:"20vw",maxWidth:'100%', zIndex:-1}} />
		<img alt="" src={barra2} style={{position:"absolute",top:0,left:0, width:"20vw", zIndex:-1}} />
		<img alt="" src={barra3} style={{position:"absolute",top:0,right:0, width:"20vw", zIndex:-1}} />
		<Router>
			<NavBar />
			<Switch>
				<Routes />
			</Switch>
		</Router>

		{(!isLoggedIn && showModalLogin ) && <Modal open={true} handleClose={()=>setShowModalLogin(false)}>
			<LoginModal dismissModal={()=>setShowModalLogin(false)} />
		</Modal>}

		</ErrorBoundary>
  );
}

export default App;
