import React from "react";
import { Route } from "react-router-dom";

import loadable from '@loadable/component'
// import "./Routes.scss";

const Home = loadable(() => import("./pages/Home"))
const Curso = loadable(() => import("./pages/Curso"))
const Paquetes = loadable(() => import("./pages/Paquetes"))
const Paquete = loadable(() => import("./pages/Paquete"))
const Clase = loadable(() => import("./pages/Clase"))
const Categorias = loadable(() => import("./pages/Categorias"))
const Perfil = loadable(() => import("./pages/Perfil"))
const Autor = loadable(() => import("./pages/Autor"))
const Contacto = loadable(() => import("./pages/Contacto"))
const ResetPassword = loadable(() => import("./pages/ResetPassword"))
const ActivarCuenta = loadable(() => import("./pages/ActivarCuenta"))
const MisCursos = loadable(() => import("./pages/MisCursos"))
const Footer = loadable(() => import("components/Footer"))

// import Home from "./pages/Home"
// import Curso from "./pages/Curso"
// import Clase from "./pages/Clase"
// import Categorias from "./pages/Categorias"
// import Perfil from "./pages/Perfil"
// import Autor from "./pages/Autor"
// import Contacto from "./pages/Contacto"
// import ResetPassword from "./pages/ResetPassword"
// import ActivarCuenta from "./pages/ActivarCuenta"
// import MisCursos from "./pages/MisCursos"
// import Footer from "components/Footer";

const Routes = () => {
	console.log("routes");
	return <Route>
				<Route exact path="/" component={Home}/>
				<Route exact path="/categorias" component={Categorias}/>
				<Route exact path="/mis-cursos" component={MisCursos}/>
				<Route exact path="/contacto" component={Contacto}/>
				<Route exact path="/perfil" component={Perfil}/>
				<Route exact path="/paquetes" component={Paquetes}/>
				<Route exact path="/paquete/:slug" component={Paquete}/>
				<Route exact path="/autor/:id_autor" component={Autor}/>
				<Route exact path="/curso/:slug" component={Curso}/>
				<Route exact path="/curso/:id_curso/clase/:id_clase?" component={Clase}/>
				<Route exact path="/activar/:token/:idUsuario" component={ActivarCuenta}/>
				<Route exact path="/reset/:token/:idUsuario" component={ResetPassword}/>
				<Footer />
		</Route>
};

export default Routes;
