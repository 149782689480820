import React, {useEffect, useState, useRef} from 'react';
import {AppBar, Toolbar, Typography, IconButton} from '@material-ui/core';
import ButtonMaterialUi from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import {isLoggedIn} from "api";
import logo from "assets/img/con_nombre.png"
import useGlobalState from "GlobalStore";
import {useQueryCache} from 'react-query';
import {useHistory} from 'react-router-dom';
import useWindowSize from "hooks/useWindowSize";
import {init} from "api";
import "./NavBar.scss";
export default function NavBar(){
	const {innerWidth} = useWindowSize();
	const [collapsed, setCollapsed] = useState(innerWidth < 769 );
	const [modalLogin, setShowModalLogin] = useGlobalState("showModalLogin");
	const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
	const [, setForceLogin] = useGlobalState("forceLogin");
	const [menu, setMenu] = useState([]);
	const queryCache = useQueryCache();
	const history = useHistory();
	const listener = useRef(null);
	const onClick = (item) => {
		setCollapsed(true);
		switch (item.value) {
			case 'cerrar_sesion':
				window.localStorage.removeItem("vitallier:user");
				setIsLoggedIn(false);
				init();
				queryCache.invalidateQueries('courses');
				break;
			case 'iniciar_sesion':
				setShowModalLogin(true);
				setForceLogin(true);
				break;
			case 'registro':
				setShowModalLogin(true);
				setForceLogin(false);
				break;
			case 'categorias':
				history.push('/categorias')
				break;
			case 'inicio':
				history.push('/')
				break;
			case 'contacto':
				history.push('/contacto')
				break;
			case 'mis_cursos':
				history.push('/mis-cursos')
				break;
			case 'paquetes':
				history.push('/paquetes')
				break;
			case 'perfil':
				history.push('/perfil')
				break;
	
			default:
				break;
		}
	
	}
	useEffect(() => {
		// ,{label:"PAQUETES", value:'paquetes'}
		if(isLoggedIn){
			setMenu([{label:"INICIO", value:'inicio'},{label:"CATEGORIAS", value:'categorias'},{label:"PAQUETES", value:'paquetes'},{label:"CONTACTO", value:'contacto'},{label:"MIS CURSOS", value:'mis_cursos'},{label:"CERRAR SESIÓN", value:'cerrar_sesion'}])
		}else{
			setMenu([{label:"INICIO", value:'inicio'},{label:"CATEGORIAS", value:'categorias'},{label:"PAQUETES", value:'paquetes'},{label:"INICIAR SESIÓN", value:'iniciar_sesion'},{label:"REGISTRO", value:'registro'}])
		}
	}, [isLoggedIn]);
	

	useEffect(() => {
		setCollapsed( innerWidth < 769 );
	}, [innerWidth]);

	const collapse = () => {
		if(innerWidth < 769) setCollapsed(!collapsed);
	};


  const [scrollState, setScrollState] = useState("top")
  useEffect(() => {
    listener.current = document.addEventListener("scroll", e => {
			var scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 40) {
        if (scrollState !== "amir") {
          setScrollState("amir")
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top")
        }
      }
		})
    return () => {
      document.removeEventListener("scroll", listener.current)
    }
	}, [scrollState])
	
	return <div style={{height:'100%', position:"sticky",width:'100%',top:0,zIndex:9}}>
		<div id="navbarsmartbanner" ></div>
		<div style={{...{backgroundColor:"transparent",color:"black"},...((scrollState==="amir" )?{backgroundColor:"white"}:{backgroundColor:"transparent"})}}>
			<div style={{padding:0,width:'100%',height:'100%', textAlign:'center',position:"relative"}} >
				<img onClick={()=>history.push('/')} edge="start"  color="inherit" aria-label="menu" alt="logo" src={logo} style={{height:60,marginTop:20,marginBottom:10}} />
				{innerWidth < 769 &&
					<div style={{position:'absolute',right:0, zIndex:999,top:30}} className="button-collapsed">
						<ButtonMaterialUi onClick={()=> {setCollapsed(!collapsed);} }>
							{collapsed && <MenuIcon style={{color:"#23483E"}} />}
							{!collapsed && <MenuOpenIcon style={{color:"#23483E"}} />}
						</ButtonMaterialUi>
					</div>
				}
			</div>
			
			{(!collapsed ||innerWidth > 769 ) &&<div className="navbar">
					{menu.map(item=><Typography style={{cursor:'pointer',fontFamily:'poppinsregular',letterSpacing: "0.1em"}} className="menu-item" variant="p" onClick={()=>onClick(item)}>
						{item.label}
					</Typography>)}
				</div>
			}
		</div>
		{/* <div style={innerWidth < 769 ? {height:120,width:'100%'}:{height:150,width:'100%'}}></div> */}
	</div>
}