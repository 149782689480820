import React, {useState, useEffect} from 'react';
import {TextField, Button, CircularProgress, FormControlLabel, Checkbox, Typography} from '@material-ui/core';
import {Alert} from "@material-ui/lab";
import logo from "assets/img/con_nombre.png";
import * as api from "api";
import {queryCache} from 'react-query';
import useGlobalState from "GlobalStore";
import swal from "@sweetalert/with-react";

function VerificationToken({dismissModal, idUsuario}){
	const [status, setStatus] = useState({status:"pendiente"});
	const [verificationToken, setVerificationToken] = useState("");
	const verificar = async() => {
		setStatus({status:"loading"});
		api.post("/user/verify/",{
			token: verificationToken,
			idUsuario: idUsuario,
		}).then((r)=>{
			api.init(r.token);				
			setStatus({status:"verificado"});
			dismissModal();
		}).catch(e=>{
			console.log(e);
			setStatus({status:"error",message:JSON.stringify(e)});
		});
	}
	return <>
		{status.status==="loading" && <>
			Estamos validando tu cuenta
		</>}
		{status.status==="success" &&
			<Alert severity="success">
				<div>Hemos verificado tu cuenta exitosamente, serás redirigido a la pagina de inicio</div>
			</Alert>
		}
		
		{(status.status==="pendiente" || status.status==="error")&& <>
			<Typography variant="h6"  style={{color:"#7B7B7B", fontWeight:"bold", textAlign:'center'}}>
				Por favor ingresa el código que te enviamos a tu correo, recuerda revisar tu bandeja de spam y de promociónes.
			</Typography>
			<TextField style={{marginBottom:10}} value={verificationToken} onChange={(e)=>(e.persist(), setVerificationToken(e.target.value))} id="outlined-basic" label="Código" variant="outlined" />
			<Button onClick={verificar} color="primary" style={{marginBottom:10, float:"right"}} variant="contained">Enviar</Button>
		</>}
		{status.status==="error" &&
				<Alert severity="error">{status.message}</Alert>
		}
	</>
}
export default function LoginModal({dismissModal}){
	const [_, setIsLoggedIn] = useGlobalState("isLoggedIn");
	const [forceLogin,setForceLogin] = useGlobalState("forceLogin");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isLogin, setIsLogin] = useState(forceLogin);
	const [askVerification, setAskVerification] = useState(false);
	const [user, setUser] = useState();

	// const [successfullRegister, setSuccessfullRegister] = useState(false);

	
	const getQueryParams = () => {
		const utm = {
			utm_source: "utm_source",
			utm_medium: "utm_medium",
			utm_campaign: "utm_campaign",
			utm_term: "utm_term",
			utm_content: "utm_content",
		};
		var params = {};
		var self = window.location.toString();
		var querystring = self.split("?");
		if (querystring.length > 1) {
			var pairs = querystring[1].split("&");
			for (const i in pairs) {
				var keyval = pairs[i].split("=");
				const utmKey = utm[keyval[0]];
				if(utmKey)
					params[utmKey] = keyval[1];
			}
		}
		return params;
	};


	const [data, setData] = useState({correo:"",password:"",nombre:""});
	const toggleLogin = () => {
		setIsLogin(t=>!t);
	}
	const onChange = name => e => {
		e.persist();
		setData(d=>({...d, [name]:e.target.value}))
	};
	const forgotPassword = async () => {
		if(!data.correo){
			setError("Por favor ingresa tu correo para resetear tu contraseña");
			return;
		}
		try{
			setIsLoading(true);
			await api.post(`/user/forgot`,{correo:data.correo});
			swal({
				text: "Hemos enviado un correo para resetear tu contraseña.",
				icon: "success",
				buttons: ["Ok"],
			})
			dismissModal();

		}catch(e){
			setIsLoading(false);

			setError(JSON.stringify(e))
		}
	}

	const register = login => async() => {
		setError(null);
		setIsLoading(true);
		try{
			if(login){
				const r = await api.login(data);
				api.init(r.token);
				queryCache.invalidateQueries("courses");
				dismissModal();
				setIsLoggedIn(true);
			}else{
				if(!data.terminos){
					alert("Es necesario que aceptes los terminos y condiciones para registrarte.")
					return;
				}
				const r = await api.register({...data, initial_referral: getQueryParams()});
				console.log({r})
				setUser(r);
				api.track("Lead", getQueryParams());
				setAskVerification(true);

			}
			
			
		}catch(e){
			console.log({e})
			if(typeof e==="string"){
				setError(e);
			}else{
				if(e.code===10003){
					setUser(e.user);
					setAskVerification(true);
				}
				else{
					setError(e.message || e.error);
				}
			}
			
		}finally{
			setIsLoading(false);
		}
	};
	
	return <div style={{textAlign:"center",padding:10}}>
		<img alt='logo' src={logo} style={{width:100, marginBottom:20}} />
		{askVerification ? <VerificationToken dismissModal={dismissModal} idUsuario={user._id} /> : <>
		{isLogin && <>
			<Typography variant="h6"  style={{color:"#7B7B7B", fontWeight:"bold", textAlign:'center'}}>
				Inicio de sesión
			</Typography>
			<TextField style={{marginBottom:10}} value={data.correo} onChange={onChange("correo")} id="outlined-basic" label="Correo" variant="outlined" />
			<TextField type="password" style={{marginBottom:10}} value={data.password} onChange={onChange("password")} id="outlined-basic" label="Password" variant="outlined" />
			{!isLoading && <Button onClick={register(true)} color="primary" style={{marginBottom:10, float:"right"}} variant="contained">Iniciar Sesion</Button>}
			<Button onClick={forgotPassword} >¿No recuerdas tu contraseña?</Button><br/>
			<Button onClick={toggleLogin} >¿No tienes cuenta? Registrate</Button>
		</>}
		{!isLogin && <>
			<Typography variant="h6"  style={{color:"#7B7B7B", fontWeight:"bold", textAlign:'center'}}>
				Registro de usuarios nuevos
			</Typography>
			<TextField style={{marginBottom:10}} value={data.nombre} onChange={onChange("nombre")} id="outlined-basic" label="Nombre" variant="outlined" />
			<TextField style={{marginBottom:10}} value={data.correo} onChange={onChange("correo")} id="outlined-basic" label="Correo" variant="outlined" />
			<TextField type="password" style={{marginBottom:10}} value={data.password} onChange={onChange("password")} id="outlined-basic" label="Password" variant="outlined" />
			<div style={{textAlign:"left"}}>
				<Checkbox
					checked={data.terminos}
					onChange={(e)=>(e.persist(),setData(d=>({...d, "terminos":e.target.checked})))}
					inputProps={{ 'aria-label': 'primary checkbox' }}
				/>
				Acepto los <a target="_blank" href="/terminos_condiciones.pdf">terminos y condiciones</a> y el <a target="_blank" href="/aviso_privacidad.pdf">aviso de privacidad</a>
			
			</div>
			<br />
			{!isLoading && <Button color="primary" onClick={register(false)} style={{marginBottom:10, float:"right"}} variant="contained">Registrarme</Button>}
			<Button onClick={toggleLogin} >Ya tienes cuenta? Inicia sesion</Button>
		</>}</>}
		{ error && <Alert severity="error">{error}</Alert> }
		{ isLoading && <CircularProgress /> }
		
	</div>
}