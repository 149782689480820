import React from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
export default function SimpleModal(props) {

  return (
		<Modal
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			style={{display: "flex",
				alignItems: "center",
				justifyContent: "center"}}
		>
    	<div style={{backgroundColor:"white", padding:20, position:'relative'}}>
				{props.closeButton && <div onClick={props.handleClose} style={{position:'absolute',top:10,right:10}}>
					<CloseIcon style={{width:30}} />
				</div>}
				{props.children}
			</div>
		</Modal>
  );
}